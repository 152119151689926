import React from "react";

function Home() {
    return (
        <div id="home">
            <h1>Hi, I'm Chris!</h1>
            <h2>I'm your next Web Developer</h2>
        </div>
    );
}

export default Home;