const chess = {
  name: "Chess",
  description:
    "This is project is command-line chess written in ruby. \
    It can either be played with 2 players or against a computer. \
    The game will end and a winner will be declared when a player is checkmated. \
    This project gave me an opportunity to practice my Object Oriented Programming skills by making classes and modules to support each distinct piece without rewriting any code. \
    I also got to write algorithms to detect if there was a check or checkmate on the board. To test my algorithms I used Rspec. ",
  siteUrl: "https://replit.com/@chrismenke45/Chess?v=1",
  githubUrl: "https://github.com/chrismenke45/chess",
  desktopPhoto: "./ProjectPhotos/chess/desktop.png",
  mobilePhoto: "./ProjectPhotos/chess/desktop.png",
  technologies: ["ruby", "rspec"],
  message:
    'This is hosted on Replit. Once you sign in you can click the "play" button and you\'ll be able to play chess!',
  id: 2,
}
export default chess
